<div class="container-dialog-set relativeClass">
  <ng-content select="[container]"></ng-content>
  @if (isLoading) {
    <div class="positionAbsolute">
      <div class="justify-center loader">
        <mat-progress-spinner color="primary" mode="indeterminate">
        </mat-progress-spinner>
      </div>
    </div>
  }
</div>

<div class="footer-container">
  <span (click)="onClose.emit()" class="text-btn-underline fw-500">
    Cancel
  </span>

  @if(!hideButton()) {
    <app-roam-button
      [disabled]="!isValid"
      [label]="buttonLabel()"
      (onClick)="onSave.emit()" />
  }
</div>
