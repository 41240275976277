import { IPageType } from "@app/shared/interfaces/page.interface";
import { IModule } from "@app/shared/interfaces";

export type PageName =
  | "financials"
  | "reporting"
  | "associations"
  | "listings"
  // | "inbox"
  | "tasks"
  | "contacts"
  // | "calendar"
  | "favorites"
  | "recent"
  | "maintenance"
  | "leasing"
  | "board-portal"
  | "settings-global"
  | "communication";

export const subMenus: Record<PageName, Partial<IPageType>[]> = {
  financials: [
    {
      type: "bills",
      title: "Bills",
      action: "Add Bill",
      dialogTitle: "New Vendor Bill",
      dialogEditModeTitle: "Edit Vendor Bill",
      tableColumns: [
        {
          name: "date",
          title: "Date",
        },
        {
          name: "transaction",
          title: "Name",
          subName: "entityName",
        },
        {
          name: "referenceNumber",
          title: "Ref No",
        },
        {
          name: "memo",
          title: "Memo",
        },
        {
          name: "apAccountName",
          title: "Account",
        },
        {
          name: "amountDue",
          title: "Amount",
        },
        {
          name: "openAmount",
          title: "Open Balance",
        },
      ],
    },
    {
      type: "bill-payments",
      title: "Bill Payments",
      action: "Add Bill Payment",
      dialogTitle: "Pay Vendor Bills",
      dialogEditModeTitle: "Edit Pay Bill",
      saveAction: "Pay Selected Bills",
      tableColumns: [
        {
          name: "date",
          title: "Date",
        },
        {
          name: "transaction",
          title: "Vendor",
          subName: "entityName",
        },
        {
          name: "referenceNumber",
          title: "Ref No",
        },
        {
          name: "memo",
          title: "Memo",
        },
        {
          name: "apAccountName",
          title: "Account",
        },
        {
          name: "amount",
          title: "Amount Paid",
        },
      ],
    },
    {
      type: "vendor-credits",
      title: "Vendor Credits",
      action: "Add Vendor Credit",
      dialogTitle: "Add Vendor Credit",
      dialogEditModeTitle: "Edit Vendor Credit",
      saveAction: "Save Vendor Credit",
      tableColumns: [
        {
          name: "date",
          title: "Date",
        },
        {
          name: "transaction",
          title: "Vendor",
          subName: "entityName",
        },
        {
          name: "referenceNumber",
          title: "Ref No",
        },
        {
          name: "memo",
          title: "Memo",
        },
        {
          name: "apAccountName",
          title: "Account",
        },
        {
          name: "creditAmount",
          title: "Credit Amount",
        },
        {
          name: "openAmount",
          title: "Amount Unapplied",
        },
      ],
    },
    {
      type: "charges",
      title: "Charges",
      action: "Add Charge",
      dialogTitle: "New Customer Charge",
      dialogEditModeTitle: "Edit Charge",
      saveAction: "Create Charge",
      tableColumns: [
        {
          name: "billedDate",
          title: "Billed date",
        },
        {
          name: "dueDate",
          title: "Due date",
        },
        {
          name: "customerName",
          title: "Customer",
        },
        {
          name: "referenceNumber",
          title: "Ref No",
        },
        {
          name: "memo",
          title: "Memo",
        },
        {
          name: "arAccountName",
          title: "Account",
        },
        {
          name: "amount",
          title: "Amount",
        },
      ],
    },
    {
      type: "checks",
      title: "Checks",
      action: "Add Check",
      dialogTitle: "New Check",
      dialogEditModeTitle: "Edit Check",
      tableColumns: [
        {
          name: "date",
          title: "Date",
        },
        {
          name: "entityName",
          title: "Entity",
        },
        {
          name: "referenceNumber",
          title: "Ref No",
        },
        {
          name: "memo",
          title: "Memo",
        },
        {
          name: "accountName",
          title: "Account",
        },
        {
          name: "amount",
          title: "Amount",
        },
      ],
    },
    {
      type: "credit-memos",
      title: "Credit Memos",
      action: "Add Credit Memo",
      dialogTitle: "Add Credit Memo",
      dialogEditModeTitle: "Edit Credit Memo",
      saveAction: "Add Credit Memo",
      tableColumns: [
        {
          name: "date",
          title: "Date",
        },
        {
          name: "customerName",
          title: "Customer",
        },
        {
          name: "referenceNumber",
          title: "Ref No",
        },
        {
          name: "memo",
          title: "Memo",
        },
        {
          name: "subtotal",
          title: "Amount",
        },
        {
          name: "creditRemaining",
          title: "Remaining",
        },
      ],
    },
    {
      type: "deposits",
      title: "Deposits",
      action: "Record Deposit",
      dialogTitle: "Record Deposit",
      dialogEditModeTitle: "Edit Deposit",
      saveAction: "Deposit Funds",
      tableColumns: [
        {
          name: "date",
          title: "Deposit Date",
        },
        {
          name: "memo",
          title: "Memo",
        },
        {
          name: "depositAccountName",
          title: "Account",
        },
        {
          name: "depositTotal",
          title: "Deposit Total",
        },
      ],
    },
    {
      type: "invoices",
      title: "Invoices",
      action: "Post Invoice",
      dialogTitle: "New Invoice",
      dialogEditModeTitle: "Edit Invoice",
      tableColumns: [
        {
          name: "date",
          title: "Invoice Date",
        },
        {
          name: "dueDate",
          title: "Due date",
        },
        {
          name: "transaction",
          title: "Customer",
          subName: "entityName",
        },
        {
          name: "referenceNumber",
          title: "Ref No",
        },
        {
          name: "memo",
          title: "Memo",
        },
        {
          name: "agingDays",
          title: "aging in days",
        },
        {
          name: "subtotal",
          title: "Amount",
        },
        {
          name: "openBalance",
          title: "open balance",
        },
      ],
    },
    {
      type: "journal-entries",
      title: "Journal Entries",
      action: "Add Journal Entry",
      dialogTitle: "New Journal Entry",
      dialogEditModeTitle: "Edit Journal Entry",
      tableColumns: [
        {
          name: "date",
          title: "date",
        },
        {
          name: "referenceNumber",
          title: "Ref No",
        },
        {
          name: "memo",
          title: "memo",
        },
        {
          name: "debitTotal",
          title: "debit",
        },
        {
          name: "creditTotal",
          title: "credit",
        },
        {
          name: "totalTransactions",
          title: "Txn #",
        },
      ],
    },
    {
      type: "received-payments",
      title: "Received Payments",
      action: "Receive Payment",
      dialogTitle: "New Customer Payment",
      dialogEditModeTitle: "Edit Customer Payment",
      tableColumns: [
        {
          name: "date",
          title: "date",
        },
        {
          name: "customerName",
          title: "Customer",
        },
        {
          name: "referenceNumber",
          title: "Ref No",
        },
        {
          name: "memo",
          title: "Memo",
        },
        {
          name: "paymentMethodName",
          title: "Payment Method",
        },
        {
          name: "totalAmount",
          title: "Amount",
        },
        {
          name: "unusedPayments",
          title: "unapplied amount",
        },
      ],
    },
    {
      type: "transfers",
      title: "Transfers",
      action: "Record Transfer",
      dialogTitle: "New Transfer",
      dialogEditModeTitle: "Edit Transfer",
      saveAction: "Transfer Funds",
      tableColumns: [
        {
          name: "date",
          title: "date",
        },
        {
          name: "memo",
          title: "Memo",
        },
        {
          name: "fromAccountName",
          title: "From Account",
        },
        {
          name: "toAccountName",
          title: "To Account",
        },
        {
          name: "amount",
          title: "Amount",
        },
      ],
    },
    {
      type: "accounts",
      title: "Accounting Lists",
      action: "Add Account",
      dialogTitle: "New Account",
      dialogEditModeTitle: "Edit Account",
      saveAction: "Create Account",
      tableColumns: [
        {
          name: "number",
          title: "Number",
        },
        {
          name: "name",
          title: "Name",
        },
        {
          name: "parentAccountName",
          title: "Parent Account",
        },
        {
          name: "type",
          title: "Type",
        },
        {
          name: "subtype",
          title: "Sub Type",
        },
        {
          name: "balance",
          title: "Balance",
        },
      ],
    },
    {
      type: "payable-templates",
      title: "Payable Templates",
      action: "Add Payable Template",
      dialogTitle: "New Payable Template",
      dialogEditModeTitle: "Edit Payable Template",
    }
  ],
  reporting: [
    // {
    //   type: "reports",
    //   title: "Reports",
    // },
    // {
    //   type: "scheduled-reports",
    //   title: "Scheduled Reports",
    // },
    // {
    //   type: "metrics",
    //   title: "Metrics",
    // },
    // {
    //   type: "surveys",
    //   title: "Surveys",
    // },
    {
      type: "compliance",
      title: "Compliance",
    },
    // {
    //   type: "revenue",
    //   title: "Revenue",
    // },
    // {
    //   type: "bookings",
    //   title: "Bookings",
    // },
    // {
    //   type: "unit-nights",
    //   title: "Unit Nights",
    // },
    {
      type: "reports",
      title: "Reports",
    },
    {
      type: "budgets",
      title: "Budgets",
    },
  ],
  associations: [
    {
      type: "",
      title: "All Associations",
    },
  ],
  listings: [
    {
      type: "main",
      title: "Main",
    },
  ],
  tasks: [
    // {
    //   type: "main",
    //   title: "Task Scheduled",
    // },
  ],
  contacts: [
    {
      type: "customers",
      title: "Customer Accounts",
      tableColumns: [
        {
          name: "name",
          title: "Name",
        },
        {
          name: "address",
          title: "Address",
          subArr: "address",
        },
        {
          name: "phones",
          title: "Phone",
          subArr: "number",
        },
        {
          name: "email",
          title: "Email",
        },
      ],
    },
    {
      type: "owners",
      title: "Homeowners",
      tableColumns: [
        {
          name: "name",
          title: "Name",
        },
        {
          name: "role",
          title: "Role",
        },
        {
          name: "address",
          title: "Address",
        },
        {
          name: "email",
          title: "Email",
        },
        {
          name: "phone",
          title: "Phone",
        },
      ],
    },
    {
      type: "tenants",
      title: "Tenants",
      tableColumns: [
        {
          name: "name",
          title: "Name",
        },
        {
          name: "role",
          title: "Role",
        },
        {
          name: "address",
          title: "Address",
        },
        {
          name: "email",
          title: "Email",
        },
        {
          name: "phone",
          title: "Phone",
        },
      ],
    },
    // {
    //   type: "managers",
    //   title: "Outside Managers",
    //   tableColumns: [
    //     {
    //       name: "name",
    //       title: "Name",
    //     },
    //     {
    //       name: "role",
    //       title: "Role",
    //     },
    //     {
    //       name: "address",
    //       title: "Address",
    //     },
    //     {
    //       name: "email",
    //       title: "Email",
    //     },
    //     {
    //       name: "phone",
    //       title: "Phone",
    //     },
    //   ],
    // },
    // {
    //   type: "agents",
    //   title: "Real Estate Agents",
    //   tableColumns: [
    //     {
    //       name: "name",
    //       title: "Name",
    //     },
    //     {
    //       name: "address",
    //       title: "Address",
    //       subArr: "address",
    //     },
    //     {
    //       name: "phones",
    //       title: "Phone",
    //       subArr: "number",
    //     },
    //     {
    //       name: "email",
    //       title: "Email",
    //     },
    //   ],
    // },
    // {
    //   type: "users",
    //   title: "User Logins",
    //   tableColumns: [
    //     {
    //       name: "name",
    //       title: "Name",
    //     },
    //     {
    //       name: "address",
    //       title: "Address",
    //       subArr: "address",
    //     },
    //     {
    //       name: "phones",
    //       title: "Phone",
    //       subArr: "number",
    //     },
    //     {
    //       name: "email",
    //       title: "Email",
    //     },
    //   ],
    // },
    // {
    //   type: "developers",
    //   title: "Developers",
    //   tableColumns: [
    //     {
    //       name: "name",
    //       title: "Name",
    //     },
    //     {
    //       name: "address",
    //       title: "Address",
    //       subArr: "address",
    //     },
    //     {
    //       name: "phones",
    //       title: "Phone",
    //       subArr: "number",
    //     },
    //     {
    //       name: "email",
    //       title: "Email",
    //     },
    //   ],
    // },
    {
      type: "vendors",
      title: "Vendors",
      tableColumns: [
        {
          name: "name",
          title: "Name",
        },
        {
          name: "addresses",
          title: "Address",
          subArr: "address",
        },
        {
          name: "trades",
          title: "Trades",
        },
        {
          name: "phones",
          title: "Phone",
          subArr: "number",
        },
        {
          name: "email",
          title: "Email",
        },
      ],
    },
    {
      type: "employees",
      title: "Employees",
      tableColumns: [
        {
          name: "name",
          title: "Name",
        },
        {
          name: "role",
          title: "Role",
        },
        {
          name: "address",
          title: "Address",
        },
        {
          name: "email",
          title: "Email",
        },
        {
          name: "phone",
          title: "Phone",
        },
      ],
    },
  ],
  favorites: [
    {
      code: "associations",
      name: "Associations",
      slug: "associations",
    },
    {
      code: "financials",
      name: "Accounting",
      slug: "financials",
    },
  ],
  recent: [
    {
      code: "tasks",
      name: "Tasks",
      slug: "tasks",
    },
    {
      code: "reporting",
      name: "Reporting",
      slug: "reporting",
    },
  ],
  maintenance: [
    {
      type: "main",
      title: "Work Orders",
    },
    {
      type: "task-scheduled",
      title: "Tasks",
    },
  ],
  leasing: [
    {
      type: "active",
      title: "Active Leases",
    },
    {
      type: "draft",
      title: "Draft Leases",
    },
    {
      type: "rental",
      title: "Rental Applications",
    },
  ],
  // "board-portal": [
  //   {
  //     type: "violation",
  //     title: "Violations",
  //   },
  //   {
  //     type: "modification",
  //     title: "ARC Requests",
  //   },
  //   {
  //     type: "inspection",
  //     title: "Inspections",
  //   },
  //   {
  //     type: "board-motion",
  //     title: "Board Motions",
  //   },
  //   {
  //     type: "meeting",
  //     title: "Meetings",
  //   },
  //   {
  //     type: "action-item",
  //     title: "Action Item",
  //   },
  //   {
  //     type: "work-order",
  //     title: "Work Order Review",
  //   },
  //   {
  //     type: "invoice",
  //     title: "Invoice Approval",
  //   },
  //   {
  //     type: "age-delinquency",
  //     title: "Aged Delinquencies",
  //   },
  //   {
  //     type: "account-receivable",
  //     title: " Accounts Receivable",
  //   },
  // ],
  "board-portal": [
    {
      type: "overview", // TODO: extend current overview page
      title: "Overview",
    },
    {
      type: "motion",
      title: "Board Motions",
    },
    {
      type: "meeting", // TODO: custom table!
      title: "Meetings",
    },
    {
      type: "violation", // TODO: custom table!
      title: "Violations",
    },
    {
      type: "architectural",
      title: "Architectural Approval",
    },
    {
      type: "inspection",
      title: "Inspections",
    },
    {
      type: "task",
      title: "Tasks",
    },
    {
      type: "work-order-review",
      title: "Work Order Reviews",
    },
    {
      type: "invoice-approval",
      title: "Invoice Approvals",
    },
    {
      type: "summarized-deliquency",
      title: "Summarized Deliquencies",
    },
    {
      type: "account-receivable",
      title: "Account Receivables",
    },
    {
      type: "customer-account",
      title: "Customer Accounts",
    },
  ],
  "settings-global": [
    {
      type: "organization",
      title: "Organization",
    },
    {
      type: "preferences",
      title: "Preferences",
    },
    {
      type: "billing",
      title: "Billing",
    },
    {
      type: "bank-accounts",
      title: "Bank Accounts",
    },
    {
      type: "permissions",
      title: "Permissions",
    },
    {
      type: "compliance",
      title: "Compliance",
    },
    {
      type: "custom-fields",
      title: "Custom Fields",
    },
  ],
  communication: [
    {
      type: "broadcast",
      title: "Broadcast",
    },
    {
      type: "mail-room",
      title: "Mail Room",
    }
  ]
};

export const modulesBottom: IModule[] = [
  {
    id: "",
    name: "Settings",
    slug: "settings-global",
    order: 19,
  },
];

export const subMenuGroup = {
  financials: {
    isGroup: true,
    categories: [
      {
        name: "Vendor",
        menus: [
          {
            type: "bills",
            title: "Bills",
            action: "Add Bill",
            dialogTitle: "New Vendor Bill",
            dialogEditModeTitle: "Edit Vendor Bill",
            tableColumns: [
              {
                name: "date",
                title: "Date",
              },
              {
                name: "transaction",
                title: "Name",
                subName: "entityName",
              },
              {
                name: "referenceNumber",
                title: "Ref No",
              },
              {
                name: "memo",
                title: "Memo",
              },
              {
                name: "apAccountName",
                title: "Account",
              },
              {
                name: "amountDue",
                title: "Amount",
              },
              {
                name: "openAmount",
                title: "Open Balance",
              },
            ],
          },
          {
            type: "bill-payments",
            title: "Bill Payments",
            action: "Add Bill Payment",
            dialogTitle: "Pay Vendor Bills",
            dialogEditModeTitle: "Edit Pay Bill",
            saveAction: "Pay Selected Bills",
            tableColumns: [
              {
                name: "date",
                title: "Date",
              },
              {
                name: "transaction",
                title: "Vendor",
                subName: "entityName",
              },
              {
                name: "referenceNumber",
                title: "Ref No",
              },
              {
                name: "memo",
                title: "Memo",
              },
              {
                name: "apAccountName",
                title: "Account",
              },
              {
                name: "amount",
                title: "Amount Paid",
              },
            ],
          },
          {
            type: "checks",
            title: "Checks",
            action: "Add Check",
            dialogTitle: "New Check",
            dialogEditModeTitle: "Edit Check",
            tableColumns: [
              {
                name: "date",
                title: "Date",
              },
              {
                name: "entityName",
                title: "Entity",
              },
              {
                name: "referenceNumber",
                title: "Ref No",
              },
              {
                name: "memo",
                title: "Memo",
              },
              {
                name: "accountName",
                title: "Account",
              },
              {
                name: "amount",
                title: "Amount",
              },
            ],
          },
          {
            type: "vendor-credits",
            title: "Vendor Credits",
            action: "Add Vendor Credit",
            dialogTitle: "Add Vendor Credit",
            dialogEditModeTitle: "Edit Vendor Credit",
            saveAction: "Save Vendor Credit",
            tableColumns: [
              {
                name: "date",
                title: "Date",
              },
              {
                name: "transaction",
                title: "Vendor",
                subName: "entityName",
              },
              {
                name: "referenceNumber",
                title: "Ref No",
              },
              {
                name: "memo",
                title: "Memo",
              },
              {
                name: "apAccountName",
                title: "Account",
              },
              {
                name: "creditAmount",
                title: "Credit Amount",
              },
              {
                name: "openAmount",
                title: "Amount Unapplied",
              },
            ],
          },
        ],
      },
      {
        name: "Customers",
        menus: [
          {
            type: "charges",
            title: "Charges",
            action: "Add Charge",
            dialogTitle: "New Customer Charge",
            dialogEditModeTitle: "Edit Charge",
            saveAction: "Create Charge",
            tableColumns: [
              {
                name: "billedDate",
                title: "Billed date",
              },
              {
                name: "dueDate",
                title: "Due date",
              },
              {
                name: "customerName",
                title: "Customer",
              },
              {
                name: "referenceNumber",
                title: "Ref No",
              },
              {
                name: "memo",
                title: "Memo",
              },
              {
                name: "arAccountName",
                title: "Account",
              },
              {
                name: "amount",
                title: "Amount",
              },
            ],
          },
          {
            type: "credit-memos",
            title: "Credit Memos",
            action: "Add Credit Memo",
            dialogTitle: "Add Credit Memo",
            dialogEditModeTitle: "Edit Credit Memo",
            saveAction: "Add Credit Memo",
            tableColumns: [
              {
                name: "date",
                title: "Date",
              },
              {
                name: "customerName",
                title: "Customer",
              },
              {
                name: "referenceNumber",
                title: "Ref No",
              },
              {
                name: "memo",
                title: "Memo",
              },
              {
                name: "subtotal",
                title: "Amount",
              },
              {
                name: "creditRemaining",
                title: "Remaining",
              },
            ],
          },
          {
            type: "invoices",
            title: "Invoices",
            action: "Post Invoice",
            dialogTitle: "New Invoice",
            dialogEditModeTitle: "Edit Invoice",
            tableColumns: [
              {
                name: "date",
                title: "Invoice Date",
              },
              {
                name: "dueDate",
                title: "Due date",
              },
              {
                name: "transaction",
                title: "Customer",
                subName: "entityName",
              },
              {
                name: "referenceNumber",
                title: "Ref No",
              },
              {
                name: "memo",
                title: "Memo",
              },
              {
                name: "agingDays",
                title: "aging in days",
              },
              {
                name: "subtotal",
                title: "Amount",
              },
              {
                name: "openBalance",
                title: "open balance",
              },
            ],
          },
          {
            type: "received-payments",
            title: "Received Payments",
            action: "Receive Payment",
            dialogTitle: "New Customer Payment",
            dialogEditModeTitle: "Edit Customer Payment",
            tableColumns: [
              {
                name: "date",
                title: "date",
              },
              {
                name: "customerName",
                title: "Customer",
              },
              {
                name: "referenceNumber",
                title: "Ref No",
              },
              {
                name: "memo",
                title: "Memo",
              },
              {
                name: "paymentMethodName",
                title: "Payment Method",
              },
              {
                name: "totalAmount",
                title: "Amount",
              },
              {
                name: "unusedPayments",
                title: "unapplied amount",
              },
            ],
          },
        ],
      },
      {
        name: "Company",
        menus: [
          {
            type: "accounts",
            title: "Accounting Lists",
            action: "Add Account",
            dialogTitle: "New Account",
            dialogEditModeTitle: "Edit Account",
            saveAction: "Create Account",
            tableColumns: [
              {
                name: "number",
                title: "Number",
              },
              {
                name: "name",
                title: "Name",
              },
              {
                name: "parentAccountName",
                title: "Parent Account",
              },
              {
                name: "type",
                title: "Type",
              },
              {
                name: "subtype",
                title: "Sub Type",
              },
              {
                name: "balance",
                title: "Balance",
              },
            ],
          },
          {
            type: "journal-entries",
            title: "Journal Entries",
            action: "Add Journal Entry",
            dialogTitle: "New Journal Entry",
            dialogEditModeTitle: "Edit Journal Entry",
            tableColumns: [
              {
                name: "date",
                title: "date",
              },
              {
                name: "referenceNumber",
                title: "Ref No",
              },
              {
                name: "memo",
                title: "memo",
              },
              {
                name: "debitTotal",
                title: "debit",
              },
              {
                name: "creditTotal",
                title: "credit",
              },
              {
                name: "totalTransactions",
                title: "Txn #",
              },
            ],
          },
        ],
      },
      {
        name: "Banking",
        menus: [
          {
            type: "deposits",
            title: "Deposits",
            action: "Record Deposit",
            dialogTitle: "Record Deposit",
            dialogEditModeTitle: "Edit Deposit",
            saveAction: "Deposit Funds",
            tableColumns: [
              {
                name: "date",
                title: "Deposit Date",
              },
              {
                name: "memo",
                title: "Memo",
              },
              {
                name: "depositAccountName",
                title: "Account",
              },
              {
                name: "depositTotal",
                title: "Deposit Total",
              },
            ],
          },
          {
            type: "transfers",
            title: "Transfers",
            action: "Record Transfer",
            dialogTitle: "New Transfer",
            dialogEditModeTitle: "Edit Transfer",
            saveAction: "Transfer Funds",
            tableColumns: [
              {
                name: "date",
                title: "date",
              },
              {
                name: "memo",
                title: "Memo",
              },
              {
                name: "fromAccountName",
                title: "From Account",
              },
              {
                name: "toAccountName",
                title: "To Account",
              },
              {
                name: "amount",
                title: "Amount",
              },
            ],
          },
          {
            type: "payable-templates",
            title: "Payable Templates",
            action: "Add Payable Template",
            dialogTitle: "New Payable Template",
            dialogEditModeTitle: "Edit Invoice",
            tableColumns: []
          }
        ],
      }
    ],
  },
};

export const submenuContactsForVendor = [
  {
    type: "customers",
    title: "Customers",
  },
  {
    type: "organizations",
    title: "Organizations",
  },
  {
    type: "employees",
    title: "Employees",
  },
];

export const submenuJobsForVendor = [
  {
    type: "jobs",
    title: "Jobs",
  },
  {
    type: "estimates",
    title: "Estimates",
  },
];

export function getSubMenusBySlug(slug: PageName): Partial<IPageType>[] {
  return subMenus[slug] ?? [];
}
