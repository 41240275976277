import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  output,
  Output,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { InlineSVGModule } from "ng-inline-svg-2";
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
} from "@angular/forms";
import { IRadio } from "@app/shared/interfaces/roam-radio.inteface";
import { RoamIconComponent } from "../roam-icon/roam-icon.component";

@Component({
  selector: "app-roam-radio-card",
  standalone: true,
  imports: [CommonModule, InlineSVGModule, RoamIconComponent],
  template: `
    <div class="d-flex f-wrap gap-10">
      <label
        *ngFor="let option of options"
        class="radio-label"
        [for]="option.id + '-' + groupIndex"
        [class.flex-1]="stretch">
        <input
          class="radio-input"
          type="radio"
          [name]="option.name + '-' + groupIndex"
          [id]="option.id + '-' + groupIndex"
          [value]="option.value"
          [checked]="
            radioControl.value === option.value || option.value === value
          "
          (change)="onChange(option.value); onChangeEvent.emit(value)" />
        <div class="radio-button svg-36">
          @if (_ic) {
            <roam-icon [name]="option.icon" />
          } @else {
            <span class="icon" [inlineSVG]="option.icon"></span>
          }
          <span class="font-14px fw-500 mt-6" [class]="labelType">
            {{ option.label }}
          </span>
        </div>
      </label>
    </div>
  `,
  styles: [
    `
      .radio-label input[type="radio"] {
        display: none;
      }

      .radio-label {
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
      }

      .radio-label .icon {
        width: 36px;
        height: 36px;
      }

      .radio-button .svg-36 svg {
        width: 36px;
        height: 36px;
      }

      .radio-button {
        min-width: 240px;
        border-radius: 9px;
        border: 1px solid rgba(143, 134, 147, 0.3);
        padding: 1rem;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        flex: 1;

        .default {
          background-color: white;
          color: #181818;
        }
      }

      .radio-label input:checked + .radio-button {
        border: 1.5px solid #91466a;
        background: #f9f6f8;
      }

      .radio-button .std {
        color: #222222;
        font-size: 1rem !important;
        font-weight: 500 !important;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RoamRadioCardComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: RoamRadioCardComponent,
      multi: true,
    },
  ],
})
export class RoamRadioCardComponent
  implements OnInit, ControlValueAccessor, Validator
{
  @Input()
  public options: IRadio[] = [];

  @Input()
  public labelType: "default" | "std" = "default";

  @Input()
  public groupIndex!: number | string;

  @Input()
  public stretch!: boolean;

  @Input()
  public value!: any;

  @Input()
  _ic: boolean = false;

  @Output()
  public onChangeEvent: EventEmitter<any> = new EventEmitter<any>();

  public radioControl: FormControl = new FormControl();

  constructor() {}

  ngOnInit(): void {
    this.radioControl.valueChanges.subscribe(value => {
      this.onChange(value);
    });
  }

  writeValue(value: any): void {
    this.radioControl.setValue(value, { emitEvent: false });
  }

  onChange(value: any): void {
    this.writeValue(value); // Update the internal control value
    this.onTouched(); // Trigger the touched callback
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.radioControl.disable() : this.radioControl.enable();
  }

  validate(control: AbstractControl): { [key: string]: any } | null {
    return this.radioControl.valid ? null : { invalid: true };
  }

  private onTouched = () => {};
}
