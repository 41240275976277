import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterLink } from "@angular/router";
import { SidebarFloatingMenuDirective } from "@app/pages/main/sidebar-menu-secondary/directive/sidebar-floating-menu.directive";
import { ArrowNavigationModule } from "@app/shared/components/arrow-navigation/arrow-navigation.module";
import { AttachmentUploadButtonComponent } from "@app/shared/components/attachment-upload-button/attachment-upload-button.component";
import { ButtonModule } from "@app/shared/components/button/button.module";
import { CheckboxModule } from "@app/shared/components/checkbox/checkbox.module";
import { ContainerDialogSetComponent } from "@app/shared/components/container-dialog-set/container-dialog-set.component";
import { DocumentCollectionModule } from "@app/shared/components/document-collection/document-collection.module";
import { FileConfigModule } from "@app/shared/components/file-config/file-config.module";
import { FilterContainerComponent } from "@app/shared/components/filter-container/filter-container.component";
import { FilterDropdownModule } from "@app/shared/components/filter-dropdown/filter-dropdown.module";
import { HeaderModule } from "@app/shared/components/header/header.module";
import { InputModule } from "@app/shared/components/input/input.module";
import { LabelStatusModule } from "@app/shared/components/label-status/label-status.module";
import { RoamCardModule } from "@app/shared/components/roam-card/roam-card.module";
import { RoamDetailComponentModule } from "@app/shared/components/roam-detail-component/roam-detail-component.module";
import { RoamDialogModule } from "@app/shared/components/roam-dialog/roam-dialog.module";
import { ChipModule } from "@app/shared/components/roam-layout/chip/chip.module";
import { ImageViewComponent } from "@app/shared/components/roam-layout/image-view/image-view.component";
import { RoamLayoutModule } from "@app/shared/components/roam-layout/roam-layout.module";
import { RoamLoaderModule } from "@app/shared/components/roam-loader/roam-loader.module";
import { RoamTextLabelModule } from "@app/shared/components/roam-text-label/roam-text-label.module";
import { RoamToastrModule } from "@app/shared/components/roam-toastr/roam-toastr.module";
import { ShareActionModule } from "@app/shared/components/share-action/share-action.module";
import { TableComponent } from "@app/shared/components/table";
import { UserViewMoreComponent } from "@app/shared/components/user-view-more/user-view-more.component";
import { ChangeDetectionModule } from "@app/shared/directives/change-detection/change-detection.module";
import { ClickModule } from "@app/shared/directives/click/click.module";
import { InputDirectiveModule } from "@app/shared/directives/input-directive/input-directive.module";
import { AccountingNegativePipe } from "@app/shared/pipes/accountingNegative.pipe";
import { CurrencyPipeModule } from "@app/shared/pipes/currency-pipe/currency-pipe.module";
import { HumanReadableTimePipe } from "@app/shared/pipes/humanReadableTime";
import { Nl2brPipe } from "@app/shared/pipes/nl2br.pipe";
import { PipeModule } from "@app/shared/pipes/pipe.module";
import { TimeAgoPipe } from "@app/shared/pipes/time-ago.pipe";
import { UncamelPipe } from "@app/shared/pipes/uncamel.pipe";
import { NgApexchartsModule } from "ng-apexcharts";
import { InlineSVGModule } from "ng-inline-svg-2";
import { QuillModule } from "ngx-quill";
import { ToastrModule } from "ngx-toastr";
import { SwiperModule } from "swiper/angular";
import { AttachmentCardComponent } from "./components/attachment-card/attachment-card.component";
import { AttachmentUploadFileComponent } from "./components/attachment-upload-file/attachment-upload.component";
import { AttachmentUploadComponent } from "./components/attachment-upload/attachment-upload.component";
import { ButtonFilterComponent } from "./components/button-filter/button-filter.component";
import { CalendarHeaderComponent } from "./components/calendar-header/calendar-header.component";
import { CardMetricComponent } from "./components/card-metric/card-metric.component";
import { ContentHeaderComponent } from "./components/content-header/content-header.component";
import { CounterInputComponent } from "./components/counter-input/counter-input.component";
import { DocumentModelComponent } from "./components/document-model/document-model.component";
import { EditActionsComponent } from "./components/edit-actions/edit-actions.component";
import { EmptyStateComponent } from "./components/empty-state/empty-state.component";
import { FileSliderComponent } from "./components/file-config/file-slider/file-slider.component";
import { FileUploaderComponent } from "./components/file-uploader";
import { TeamFiltersComponent } from "./components/filters/team-filters/team-filters.component";
import { LeftSideBarComponent } from "./components/left-side-bar/left-side-bar.component";
import { RoamAccordionModule } from "./components/roam-accordion/roam-accordion.module";
import { RoamBreadcrumbComponent } from "./components/roam-breadcrumb/roam-breadcrumb.component";
import { RoamCalendarComponent } from "./components/roam-calendar/roam-calendar.component";
import { RoamCardSelectComponent } from "./components/roam-card-select/roam-card-select.component";
import { RoamCardTwoComponent } from "./components/roam-card-two/roam-card-two.component";
import { RoamContainerComponent } from "./components/roam-container/roam-container.component";
import { FloatingMenuBarComponent } from "./components/roam-floating-menu/floating-menu-bar/floating-menu-bar.component";
import { RoamFloatingMenuComponent } from "./components/roam-floating-menu/roam-floating-menu.component";
import { RoamFormInfoComponent } from "./components/roam-form-info/roam-form-info.component";
import { RoamIconComponent } from "./components/roam-icon/roam-icon.component";
import { RoamLabelComponent } from "./components/roam-label/roam-label.component";
import { RoamLayoutContentFillComponent } from "./components/roam-layout-content-fill/roam-layout-content-fill.component";
import { RoamLayoutContentLegacyComponent } from "./components/roam-layout-content-legacy/roam-layout-content-legacy.component";
import { RoamRadioCardComponent } from "./components/roam-radio-card/roam-radio-card.component";
import { RoamSelectTwoComponent } from "./components/roam-select-two/roam-select-two.component";
import { RoamSwiperMetricsComponent } from "./components/roam-swiper-metrics/roam-swiper-metrics.component";
import { RoamTableTypeComponent } from "./components/roam-table-type/roam-table-type.component";
import { CellTableModule } from "./components/roam-table/components/cell-table/cell-table.module";
import { RoamTableComponent } from "./components/roam-table/roam-table.component";
import { RoamTextAreaComponent } from "./components/roam-text-area/roam-text-area.component";
import { RoamTimeComponent } from "./components/roam-time/roam-time.component";
import { RoamTitleTextComponent } from "./components/roam-title-text/roam-title-text.component";
import { SearchFilterComponent } from "./components/search-filter/search-filter.component";
import { SearchInputComponent } from "./components/search-input/search-input.component";
import { ShortcutMenuCardComponent } from "./components/shortcut-menu/shortcut-menu-card";
import { StatusChipComponent } from "./components/status-chip";
import { FilterDialogComponent } from "./dialogs/filter-dialog/filter-dialog.component";
import { ActiveClassDirective } from "./directives/active-class.directive";
import { AutofocusDirective } from "./directives/autofocus.directive";
import { LazyImageDirective } from "./directives/lazy-image.directive";
import { SportPropagate } from "./directives/stop-propagate.directive";
import { ErrorRoutingModule } from "./error-routing/error-routing.module";
import { ComponentLoaderServiceModule } from "./features/load-component.feature";
import { MaterialModule } from "./material/material.module";
import { AbsolutePipe } from "./pipes/absolute.pipe";
import { ShowAllArrayPipe } from "./pipes/array/show-all-array.pipe";
import { ShowNthArrayPipe } from "./pipes/array/show-nth-array.pipe";
import { PhonePipe } from "./pipes/phone.pipe";
import { TranslatePipe } from "./pipes/translate.pipe";
import { SelectedTableActionComponent } from "./components/selected-table-action/selected-table-action.component";

const PIPES = [
  AbsolutePipe,
  AccountingNegativePipe,
  HumanReadableTimePipe,
  Nl2brPipe,
  PhonePipe,
  ShowAllArrayPipe,
  ShowNthArrayPipe,
  TranslatePipe,
  TimeAgoPipe,
  UncamelPipe,
];

const DIRECTIVES = [
  ActiveClassDirective,
  AutofocusDirective,
  LazyImageDirective,
  SportPropagate,
  SidebarFloatingMenuDirective,
];

const COMPONENTS = [
  AttachmentCardComponent,
  AttachmentUploadComponent,
  AttachmentUploadButtonComponent,
  AttachmentUploadFileComponent,
  ButtonFilterComponent,
  CalendarHeaderComponent,
  ContainerDialogSetComponent,
  ContentHeaderComponent,
  CounterInputComponent,
  CardMetricComponent,
  DocumentModelComponent,
  EditActionsComponent,
  EmptyStateComponent,
  FilterContainerComponent,
  FilterDialogComponent,
  FloatingMenuBarComponent,
  ImageViewComponent,
  LeftSideBarComponent,
  RoamBreadcrumbComponent,
  RoamCalendarComponent,
  RoamCardTwoComponent,
  RoamCardSelectComponent,
  RoamContainerComponent,
  RoamFloatingMenuComponent,
  RoamFormInfoComponent,
  RoamIconComponent,
  RoamLabelComponent,
  RoamLayoutContentFillComponent,
  RoamLayoutContentLegacyComponent,
  RoamRadioCardComponent,
  RoamSelectTwoComponent,
  RoamSwiperMetricsComponent,
  RoamTableComponent,
  RoamTableTypeComponent,
  RoamTextAreaComponent,
  RoamTimeComponent,
  RoamTitleTextComponent,
  ShortcutMenuCardComponent,
  SearchFilterComponent,
  SearchInputComponent,
  StatusChipComponent,
  TableComponent,
  TeamFiltersComponent,
  UserViewMoreComponent,
  SelectedTableActionComponent
];

const MODULES = [
  ArrowNavigationModule,
  ButtonModule,
  CellTableModule,
  ChangeDetectionModule,
  CheckboxModule,
  ChipModule,
  ClickModule,
  CommonModule,
  ComponentLoaderServiceModule,
  CurrencyPipeModule,
  DocumentCollectionModule,
  ErrorRoutingModule,
  FileConfigModule,
  FileSliderComponent,
  FileUploaderComponent,
  FilterDropdownModule,
  FormsModule,
  HeaderModule,
  InlineSVGModule,
  InputDirectiveModule,
  InputModule,
  LabelStatusModule,
  PipeModule,
  MaterialModule,
  NgApexchartsModule,
  QuillModule,
  ReactiveFormsModule,
  RoamAccordionModule,
  RoamCardModule,
  RoamDialogModule,
  RoamLayoutModule,
  RoamLoaderModule,
  RoamDetailComponentModule,
  RoamTextLabelModule,
  RoamToastrModule,
  RouterLink,
  ShareActionModule,
  SwiperModule,
  ToastrModule,
];

@NgModule({
  imports: [
    ...PIPES,
    ...DIRECTIVES,
    ...COMPONENTS,
    ...MODULES
  ],
  exports: [
    ...PIPES,
    ...DIRECTIVES,
    ...COMPONENTS,
    ...MODULES
  ],
})
export class SharedModule {}
